import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { GagglLogo } from '@assets/GagglLogo';
import { useAppContext } from '@contexts/AppContext';
import * as dayjs from 'dayjs';
import { isEmpty, keyBy, sortBy } from 'lodash';
import { AREAS_OF_IMPROVEMENT_ENABLED } from 'src/constants/FeatureFlags';

import API from '@api/API';

import {
  Entertainer,
  EntertainerLink,
  EntertainerType,
  TYPES_RECORD,
  UnauthorizedPublicEntertainer,
  useCurrentEntertainerQuery,
  useCurrentUserEntertainerCritiqueQuery,
  useEntertainerByBookingHandleQuery,
  useEntertainerReviewsQuery,
  useTopEntertainersQuery,
} from '@queries/EntertainerQueries';

import { useCritiqueDocumentMutation } from '@mutations/CritiqueMutations';

import { useTipEntertainer } from '@hooks/EntertainerHooks/useTipEntertainer';
import { useAnalytics } from '@hooks/useAnalytics';
import { useExportComponentToImage } from '@hooks/useExportComponentToImage';
import { useShareLink } from '@hooks/useShareLink';

import { useNavigate, useParams, useQueryParams, useRouter } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import EventCard, { EventHeader, EventImage } from '@components/cards/EventCard';
import { PostCard } from '@components/cards/PostCard/PostCard';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';
import { PrimaryCritiqueButtons } from '@components/overlays/CritiqueOverlay/CritiqueOverlay';

import { useAuthGate } from '@screens/LogInScreen/AuthGateContext';

import { VenmoLogo } from '../../../assets/VenmoLogo';
import { CreateEditEntertainerProfileForm } from '../CreateEntertainerProfileScreen';
import { CritiqueList } from '../EntertainerRankingsScreen/EntertainerCritiquePopup';
import { EditEntertainerPopup } from './EditEntertainerPopup';
import { EntertainerCompetitionCard } from './EntertainerCompetitionCard';
import { EntertainerMediaCard } from './EntertainerMediaCard';
import { sendEntertainerDetailsPageView } from './sendEntertainerDetailsPageView';

const EntertainerRankTitle = ({
  entertainer,
  city,
  isPreviewMode,
}: {
  entertainer: UnauthorizedPublicEntertainer;
  city: UnauthorizedPublicEntertainer['cities'][number];
  isPreviewMode: boolean;
}) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { bookingHandle } = useParams<{ bookingHandle: string }>();

  const { openAlertDialog } = useDialog();

  const showPreviewAlert = async () => {
    openAlertDialog({
      title: 'Preview Mode',
      text: 'This feature is disabled in preview mode. Please go to your "werqwith.me" link to view the full experience.',
    });
  };

  const handleClick = () => {
    if (isPreviewMode) {
      showPreviewAlert();
      return;
    }

    track('Click Entertainer Rank Title');
    navigate(`/noauth/entertainers/${entertainer.type}/${city._id}/rankings`, {
      state: {
        back: `/noauth/entertainer/${bookingHandle}`,
      },
    });
  };

  return (
    <div className="d-flex" onClick={handleClick}>
      <span>
        Ranked #{city.rank} of {city.total} in{' '}
        <span style={{ textDecoration: 'underline' }}>{city.name}</span>
      </span>
      <div className="ms-auto">
        <Button endIcon={<FontAwesomeIcon name="chevron-right" />} size="sm" variant="flat">
          Leaderboard
        </Button>
      </div>
    </div>
  );
};

const ClaimEntertainerSection: FC<{
  entertainer: UnauthorizedPublicEntertainer;
  onClaim: () => void;
}> = ({ entertainer, onClaim }) => {
  const { entertainer: currentEntertainer } = useCurrentEntertainerQuery();
  const { bookingHandle } = useParams<{ bookingHandle: string }>();
  const { track } = useAnalytics();

  const { didCopy, shareLink } = useShareLink();
  const handleNotMe = async () => {
    track('Click Not Me');
    await shareLink({ url: `https://werqwith.me/${bookingHandle}` });
  };

  if (currentEntertainer) {
    return (
      <EmptyState
        title="Share Their Profile"
        text={`A Gaggl profile has not been set up for ${entertainer.title}. If you know them, click the button to copy and share their profile link with them so they can get some love!`}
        button={{
          text: didCopy ? 'Copied!' : 'Copy Link',
          onClick: handleNotMe,
        }}
      />
    );
  }

  return (
    <EmptyState
      title="Claim This Profile"
      text={`A Gaggl profile has not been set up for ${entertainer.title}. If this is you, click the button below to set up your free page for reviews, tips, and bookings. If this is not you but you know them, share their profile link with them so they can get some love!`}
      button={{
        text: 'Yes, this is me',
        onClick: onClaim,
      }}
      secondaryButton={{
        text: didCopy ? 'Link Copied!' : 'No, but I know them!',
        onClick: handleNotMe,
      }}
    />
  );
};

export const EntertainerOnboardingLinkButton: FC<{ back: string }> = ({ back }) => {
  const { track } = useAnalytics();
  const { navigate } = useRouter();
  const { createAuthGateHandler } = useAuthGate();

  const handleEntertainerOnboard = async () => {
    track('Click entertainer onboarding');
    createAuthGateHandler(async () => {
      navigate(`/entertainer/onboarding`, {
        state: {
          back,
        },
      });
    });
  };

  return (
    <LinkBanner
      icon={
        <IconButton className="mx-auto" badge variant="smooth" color="neutral" size="lg">
          <FontAwesomeIcon name="envelope" />
        </IconButton>
      }
      title="Are you a drag artist?"
      description="Create your own free landing page for reviews, tips, and bookings!"
      onClick={handleEntertainerOnboard}
    />
  );
};

const LINK_ICON_RECORD: Record<string, ReactElement> = {
  amazonWishlist: <FontAwesomeIcon faStyle="fab" name="amazon" />,
  venmo: (
    <div style={{ width: 16, height: 16 }}>
      <VenmoLogo />
    </div>
  ),
  instagram: <FontAwesomeIcon faStyle="fab" name="instagram" />,
  tiktok: <FontAwesomeIcon faStyle="fab" name="tiktok" />,
  email: <FontAwesomeIcon faStyle="far" name="envelope" />,
};

const EntertainerLinkButton = ({ link }: { link: EntertainerLink }) => {
  const { track } = useAnalytics();

  return (
    <Button
      startIcon={LINK_ICON_RECORD[link.type]}
      variant="outlined"
      color="neutral"
      onClick={() => {
        track('Click Entertainer Link', {
          type: link.type,
          url: link.url,
        });
        openExternalUrl(link.url);
      }}
    >
      <span style={{ whiteSpace: 'nowrap' }}>{link.text}</span>
    </Button>
  );
};

export const LinksSection: FC<{ entertainer: UnauthorizedPublicEntertainer }> = ({
  entertainer,
}) => {
  const { entertainer: currentEntertainer } = useCurrentEntertainerQuery();
  const isCurrentEntertainer = currentEntertainer?._id === entertainer?._id;

  const entertainerLinks = useMemo(() => {
    const links = [
      entertainer?.contact?.venmo && {
        type: 'venmo',
        text: 'Venmo',
        url: `venmo://users/${entertainer.contact.venmo}`,
      },
      entertainer?.contact?.instagram && {
        type: 'instagram',
        text: 'Instagram',
        url: `https://instagram.com/${entertainer?.contact?.instagram}`,
      },
      entertainer?.contact?.tiktok && {
        type: 'tiktok',
        text: 'TikTok',
        url: `https://tiktok.com/@${entertainer.contact.tiktok}`,
      },
      ...(entertainer?.contact?.links ?? []),
      entertainer?.contact?.email && {
        type: 'email',
        text: 'Email',
        url: `mailto:${entertainer?.contact?.email}`,
      },
      entertainer?.contact?.website && {
        type: 'website',
        text: 'Website',
        url: entertainer?.contact?.website,
      },
    ];

    return links.filter(Boolean);
  }, [entertainer]);

  if (!entertainerLinks.length) {
    return <></>;
  }

  return (
    <div className="mt-3">
      <HorizontalScroll
        items={[
          <div className="d-flex gap-1">
            {entertainerLinks.map((link) => (
              <div key={link.type} className="flex-grow-0">
                <EntertainerLinkButton link={link} />
              </div>
            ))}
            {isCurrentEntertainer && false && (
              <div className="flex-grow-0">
                <Button
                  className="h-100"
                  startIcon={<FontAwesomeIcon name="plus" />}
                  variant="smooth"
                  color="neutral"
                  onClick={() => {}}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>Add Link</span>
                </Button>
              </div>
            )}
          </div>,
        ]}
      />
    </div>
  );
};

export const EntertainerDetailsScreen: FC<{
  back?: string;
  preview?: boolean;
  bookingHandle?: string;
}> = ({ bookingHandle, back, preview: isPreviewMode }) => {
  const { referrer, werqwithme_redirect } = useQueryParams<{
    referrer?: string;
    werqwithme_redirect?: 'true';
  }>();
  const werqwithme = werqwithme_redirect === 'true';
  const { entertainer: currentEntertainer } = useCurrentEntertainerQuery();
  const {
    entertainer,
    upcomingEntertainerEvents,
    activeEntertainerCompetitions,
    entertainerIsReady,
  } = useEntertainerByBookingHandleQuery(bookingHandle);
  const { reviews, userPrimaryRating, refetchReviews } = useEntertainerReviewsQuery(
    entertainer?._id,
  );
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const meta = referrer ? { referrer } : undefined;

  const { userIsLoggedIn } = useAppContext();
  const { createAuthGateHandler } = useAuthGate();
  const [hideImage, setHideImage] = useState(false);
  const [primaryRating, setPrimaryRating] = useState<string>();
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const { handleVenmoEntertainer, openTipEntertainerPopup, tipEntertainerPopup } =
    useTipEntertainer();
  const { userEntertainerCritique, userEntertainerCritiqueIsReady } =
    useCurrentUserEntertainerCritiqueQuery(entertainer?._id ?? '', userIsLoggedIn);

  const { openAlertDialog } = useDialog();

  const showPreviewAlert = async () => {
    openAlertDialog({
      title: 'Preview Mode',
      text: 'This feature is disabled in preview mode. Please go to your "werqwith.me" link to view the full experience.',
    });
  };

  const handleTip = () => {
    handleVenmoEntertainer({
      entertainerId: entertainer._id,
      venmo: entertainer?.contact?.venmo,
    });
  };

  const handleBook = () => {
    navigate(`/noauth/book/${bookingHandle}`, {
      state: {
        back: `/noauth/entertainer/${bookingHandle}`,
      },
    });
  };

  const { isSubmitting: isSubmittingCritique, critiqueDocumentAsync } =
    useCritiqueDocumentMutation();
  const handleUpdatePrimaryRating = (updatedPrimaryRating: string) => {
    track('Click Primary Critique', {
      primaryRating: updatedPrimaryRating,
    });
    createAuthGateHandler(async () => {
      setPrimaryRating(updatedPrimaryRating);
      await critiqueDocumentAsync({
        documentType: 'entertainers',
        documentId: entertainer._id,
        primaryCritique: updatedPrimaryRating,
        meta,
      });
      await refetchReviews();
    });
  };

  //   const { isSubmitting: isSubmittingReview, createEntertainerReviewAsync } =
  //     useCreateEntertainerReview(entertainer?._id);
  const [reviewFieldIsVisible, setReviewFieldIsVisible] = useState<boolean>(false);
  const [reviewText, setReviewText] = useState<string>('');
  const handleSubmitReview = async () => {
    track('Click Submit Review', {
      entertainerId: entertainer._id,
      reviewText,
    });
    createAuthGateHandler(async () => {
      if (!reviewText || isSubmittingCritique) {
        return;
      }

      await critiqueDocumentAsync({
        documentType: 'entertainers',
        documentId: entertainer._id,
        comment: reviewText,
        meta,
      });
      await refetchReviews();

      setReviewText('');
    });
  };

  const handleSubmitStrength = async (updatedStrengths: string[]) => {
    track('Click Submit Strength', {
      entertainerId: entertainer._id,
      updatedStrengths,
    });
    createAuthGateHandler(async () => {
      await critiqueDocumentAsync({
        documentType: 'entertainers',
        documentId: entertainer._id,
        like: updatedStrengths,
        meta,
      });
    });
  };

  const handleSubmitImprovement = async (updatedImprovements: string[]) => {
    track('Click Submit Improvement', {
      entertainerId: entertainer._id,
      updatedImprovements,
    });
    createAuthGateHandler(async () => {
      await critiqueDocumentAsync({
        documentType: 'entertainers',
        documentId: entertainer._id,
        neutral: updatedImprovements,
        meta,
      });
    });
  };

  const handleEntertainerOnboard = async () => {
    track('Click entertainer onboarding');
    createAuthGateHandler(async () => {
      navigate(`/entertainer/onboarding`, {
        state: {
          back: `/noauth/entertainer/${bookingHandle}`,
        },
      });
    });
  };

  const handleClaim = () => {
    track('Click Claim Entertainer', { entertainerId: entertainer._id });
    createAuthGateHandler(async () => {
      if (entertainer.contact?.instagram) {
        const { entertainer: fetchedEntertainer } = await API.get<{ entertainer: Entertainer }>(
          `/v1/entertainer/instagram/${entertainer.contact?.instagram}`,
        );
        navigate(`/entertainer/onboarding`, {
          state: { entertainer: fetchedEntertainer },
        });
      }
    });
  };

  const didShowPreviewAlert = useRef(false);
  useEffect(() => {
    if (isPreviewMode && !didShowPreviewAlert.current) {
      didShowPreviewAlert.current = true;
      openAlertDialog({
        title: 'Preview Mode',
        text: 'This is a preview of your "werqwith.me" link. Share this link with your fans on social media give them an easy way to rate you and get tips and bookings!',
      });
    }
  }, [isPreviewMode]);

  const didRedirectToCurrentEvent = useRef(false);
  useEffect(() => {
    const currentEvent = upcomingEntertainerEvents?.find(({ isHappeningNow }) => isHappeningNow);

    if (currentEvent && !didRedirectToCurrentEvent.current) {
      didRedirectToCurrentEvent.current = true;
      navigate(`/events/${currentEvent._id}`);
    }
  }, [upcomingEntertainerEvents]);

  useEffect(() => {
    if (!isPreviewMode && entertainer && entertainer._id) {
      sendEntertainerDetailsPageView(entertainer._id);
    }
  }, [entertainer]);

  const { isClaimed } = entertainer ?? {};
  const isCurrentEntertainer = currentEntertainer?._id === entertainer?._id;

  const handleCreateEvent = () => {
    if (isCurrentEntertainer) {
      navigate('/manage/entertainer/events/create');
    }
  };

  const [primaryCity] = entertainer?.cities ?? [];

  const { didCopy, shareLink } = useShareLink();
  const handleShareClick = async () => {
    track('Click share entertainer');
    await shareLink({
      url: `https://werqwith.me/${bookingHandle}`,
    });
  };

  return (
    <Screen
      name="Entertainer Details Screen"
      disableLocationPermissionGate
      headerProps={{
        title: <Screen.GagglTitle />,
        back: back ? { to: back } : undefined,
        right: userIsLoggedIn ? (
          <>
            {isCurrentEntertainer && (
              <Button
                roundness="rounded"
                variant="smooth"
                color="neutral"
                onClick={() => {
                  track('Manage Profile');
                  navigate('/manage/entertainer');
                }}
              >
                Manage Profile
              </Button>
            )}
          </>
        ) : (
          <PopoverWrapper
            PopoverContent={({ onClose }) => (
              <div>
                <Button
                  className="w-100"
                  variant="flat"
                  color="neutral"
                  onClick={() => {
                    track('Entertainer Details Log In');
                    onClose();
                    createAuthGateHandler(() => {});
                  }}
                >
                  Log In
                </Button>
              </div>
            )}
          >
            <IconButton variant="smooth" color="neutral">
              <FontAwesomeIcon name="ellipsis-h" className="fs-6" />
            </IconButton>
          </PopoverWrapper>
        ),
      }}
      metaData={{
        title: `${entertainer?.title} — #${primaryCity?.rank} Drag Performer in ${primaryCity?.name}`,
        description: `Check out ${entertainer?.title}! Rate, reviews, tip and book them for your next event!`,
      }}
      bottomToolbar={
        entertainer?.isPublic && (
          <Screen.BottomToolbar maxWidth={425}>
            <div className="d-flex gap-2">
              {isClaimed && (
                <>
                  {/* <Button onClick={() => openTipEntertainerPopup(entertainer)}>Tip</Button> */}
                  {entertainer?.contact?.venmo && (
                    <Button
                      roundness="rounded"
                      startIcon={
                        <div style={{ width: 18, height: 18 }}>
                          <VenmoLogo />
                        </div>
                      }
                      onClick={handleTip}
                    >
                      Send a Tip
                    </Button>
                  )}
                  <Button
                    roundness="rounded"
                    variant="outlined"
                    color="neutral"
                    onClick={handleBook}
                  >
                    Book
                  </Button>
                </>
              )}
            </div>
          </Screen.BottomToolbar>
        )
      }
    >
      <Screen.Content maxWidth={425}>
        {entertainerIsReady ? (
          <>
            {isClaimed && (
              <div>
                <Card>
                  <AsyncImage
                    placeholder={!hideImage ? <Skeleton height={240} /> : undefined}
                    className="w-100"
                    src={entertainer.avatar}
                    onError={() => setHideImage(true)}
                  />
                </Card>
              </div>
            )}
            <div className="mt-3">
              {entertainer?.cities?.map((city) => (
                <EntertainerRankTitle
                  key={city._id}
                  entertainer={entertainer}
                  city={city}
                  isPreviewMode={isPreviewMode}
                />
              ))}
            </div>
            <TitleToolbar
              text={entertainer.title}
              className="mb-0 mt-0"
              action={
                isClaimed ? (
                  <Button
                    variant="smooth"
                    roundness="rounded"
                    size="sm"
                    color="neutral"
                    startIcon={
                      didCopy ? (
                        <FontAwesomeIcon name="check" />
                      ) : (
                        <FontAwesomeIcon name="share-square" />
                      )
                    }
                    className="opacity-90"
                    onClick={handleShareClick}
                  >
                    {didCopy ? 'Copied!' : 'Share'}
                  </Button>
                ) : (
                  <Button roundness="rounded" onClick={handleClaim}>
                    Claim
                  </Button>
                )
              }
            />
            {!!entertainer.tagline && (
              <div>
                {entertainer.tagline.split('\n').map((paragraph, index) => (
                  <p key={index} className="m-0">
                    <strong>{paragraph}</strong>
                  </p>
                ))}
              </div>
            )}

            <LinksSection entertainer={entertainer} />

            {isClaimed ? (
              <>
                {!!entertainer.description && mapStringToParagraphs(entertainer.description)}
                {entertainer?.details?.categories?.length && (
                  <div className="mb-2">
                    {entertainer?.details?.categories.map((category) => (
                      <div key={category} className="d-inline-block me-2 mb-2">
                        <Button
                          variant="default"
                          color="default"
                          roundness="rounded"
                          disabled={false}
                          onClick={() =>
                            track('Click Entertainer Category', {
                              category,
                            })
                          }
                        >
                          {category}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
                {isCurrentEntertainer && (
                  <Button
                    variant="smooth"
                    color="neutral"
                    roundness="rounded"
                    onClick={() => {
                      setIsEditPopupVisible(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                )}
              </>
            ) : (
              <div className="my-3">
                <ClaimEntertainerSection entertainer={entertainer} onClaim={handleClaim} />
              </div>
            )}

            {/* <SendEntertainerBadgeButton entertainerId={entertainer._id} /> */}

            {!!entertainer?.featuredMedia?.length && (
              <>
                <TitleToolbar text="Featured Looks 🧚🏽" size="md" />
                {entertainer?.featuredMedia?.length > 1 ? (
                  <HorizontalScroll
                    items={entertainer?.featuredMedia?.map((media) => (
                      <EntertainerMediaCard
                        key={media._id}
                        entertainerId={entertainer._id}
                        media={media}
                        imageHeight={240}
                        meta={{ werqwithme }}
                      />
                    ))}
                  />
                ) : (
                  <EntertainerMediaCard
                    entertainerId={entertainer._id}
                    media={entertainer.featuredMedia?.[0]}
                    meta={{ werqwithme }}
                  />
                )}
              </>
            )}

            {!userPrimaryRating && !isCurrentEntertainer && (
              <>
                {entertainer?.isPublic && (
                  <>
                    <div
                      className="d-flex flex-column align-items-center mt-4 mb-3"
                      style={{
                        border: '1px solid rgba(255, 255, 255, .5)',
                        borderRadius: '1rem',
                        padding: '8px 16px 16px 16px',
                      }}
                    >
                      <TitleToolbar text="Rate This Drag Artist" size="sm" />
                      <PrimaryCritiqueButtons
                        value={primaryRating ?? userPrimaryRating}
                        onChange={handleUpdatePrimaryRating}
                      />
                    </div>
                  </>
                )}

                {!currentEntertainer && (
                  <LinkBanner
                    icon={
                      <IconButton
                        className="mx-auto"
                        badge
                        variant="smooth"
                        color="neutral"
                        size="lg"
                      >
                        <FontAwesomeIcon name="envelope" />
                      </IconButton>
                    }
                    title="Are you a drag artist?"
                    description="Create your own free landing page for reviews, tips, and bookings!"
                    onClick={handleEntertainerOnboard}
                  />
                )}
              </>
            )}

            {!!activeEntertainerCompetitions?.length && (
              <>
                <TitleToolbar text="Active Competitions 👑" size="md" />
                <HorizontalScroll
                  items={activeEntertainerCompetitions.map((activeCompetition) => (
                    <EntertainerCompetitionCard
                      competition={activeCompetition}
                      entertainer={entertainer}
                    />
                  ))}
                />
              </>
            )}

            {(!!upcomingEntertainerEvents?.length || isCurrentEntertainer) && (
              <>
                <TitleToolbar
                  text="Upcoming Events 🎉"
                  size="md"
                  action={
                    isCurrentEntertainer && (
                      <Button
                        variant="flat"
                        size="sm"
                        startIcon={<FontAwesomeIcon name="plus" />}
                        onClick={handleCreateEvent}
                      >
                        Event
                      </Button>
                    )
                  }
                />
                {isCurrentEntertainer && !upcomingEntertainerEvents.length ? (
                  <EmptyState
                    title="No upcoming events"
                    text="You aren't sharing any upcoming events. Add an event to improve your reach."
                    button={{ text: 'Add Event', onClick: handleCreateEvent }}
                  />
                ) : (
                  <HorizontalScroll
                    items={upcomingEntertainerEvents.map((event) => (
                      <Card
                        key={event._id}
                        className="event-card"
                        onClick={() => {
                          track('Click Event Card', {
                            eventId: event._id,
                          });
                          navigate(`/events/${event._id}`);
                        }}
                      >
                        <EventHeader event={event} hideRsvp />
                        <EventImage event={event} />
                        <Card.Footer>
                          {event.externalUrl ? (
                            <Button
                              roundness="rounded"
                              onClick={(e) => {
                                e.stopPropagation();
                                openExternalUrl(event.externalUrl);
                              }}
                            >
                              More Info
                            </Button>
                          ) : (
                            <Button roundness="rounded" color="neutral" variant="smooth">
                              See Details
                            </Button>
                          )}
                        </Card.Footer>
                      </Card>
                    ))}
                  />
                )}
              </>
            )}

            {entertainer?.isPublic && (
              <>
                {((userIsLoggedIn && userEntertainerCritiqueIsReady) || !userIsLoggedIn) && (
                  <>
                    <TitleToolbar text="Strengths" size="md" className="mb-0" />
                    <div className="mb-2" style={{ fontSize: 12, lineHeight: 1.2, opacity: 0.7 }}>
                      <span>
                        Anonymous community feedback on what this entertainer is good at.{' '}
                        {entertainer?.tags?.like?.length
                          ? 'Tap "Yas!" if you agree or add your own!'
                          : 'Be the first to support them!'}
                      </span>
                    </div>
                    <CritiqueList
                      selectedTags={userEntertainerCritique?.like ?? []}
                      tags={entertainer?.tags?.like}
                      agreeBtnText="Yas!"
                      inputPlaceholder="What are they good at?"
                      onSubmit={handleSubmitStrength}
                    />

                    {AREAS_OF_IMPROVEMENT_ENABLED && (
                      <>
                        <TitleToolbar text="Areas for Improvement" size="md" />
                        <div
                          className="mb-2"
                          style={{ fontSize: 12, lineHeight: 1.2, opacity: 0.7 }}
                        >
                          <span>
                            Anonymous community feedback on what this entertainer could improve on.{' '}
                            {entertainer?.tags?.neutral?.length
                              ? 'Tap "Truth!" if you agree or add your own!'
                              : 'Be the first provide constructive feedback!'}
                          </span>
                        </div>
                        {AREAS_OF_IMPROVEMENT_ENABLED ? (
                          <CritiqueList
                            selectedTags={userEntertainerCritique?.neutral ?? []}
                            tags={entertainer?.tags?.neutral}
                            agreeBtnText="Truth!"
                            inputPlaceholder="What could they work on?"
                            onSubmit={handleSubmitImprovement}
                          />
                        ) : (
                          <EmptyState
                            title="Section Locked"
                            text={
                              'Gaggl is committed to being a positive community. After receiving feedback about misuse of this section, it has been locked while we work on ensuring it’s used for constructive feedback. We apologize to those affected by cyberbullying and are taking steps to make Gaggl safer for everyone.'
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                <TitleToolbar
                  text="Reviews 🌟"
                  size="md"
                  action={
                    <Button
                      variant="flat"
                      startIcon={<FontAwesomeIcon name="plus" />}
                      onClick={() => {
                        if (isPreviewMode) {
                          showPreviewAlert();
                          return;
                        }

                        track('Title Toolbar Action', {
                          text: 'Add Review',
                        });
                        setReviewFieldIsVisible(true);
                      }}
                    >
                      Review
                    </Button>
                  }
                />

                {reviewFieldIsVisible && (
                  <div className="mb-4">
                    <Textarea
                      onChange={setReviewText}
                      submitButton={{
                        text: 'Review',
                        // disabled: isSubmittingReview,
                        disabled: isSubmittingCritique,
                        onClick: handleSubmitReview,
                      }}
                    />
                  </div>
                )}

                {!!reviews?.length ? (
                  <div>
                    {reviews.map((review) => (
                      <PostCard
                        key={review._id}
                        post={review}
                        showDetails={false}
                        hideHeaderAction
                        disableLazyLoad
                      />
                    ))}
                  </div>
                ) : (
                  <EmptyState
                    title="No reviews yet"
                    text="Be the first to leave an anonymous review for this entertainer"
                    button={{
                      text: 'Add Review',
                      onClick: () => {
                        if (isPreviewMode) {
                          showPreviewAlert();
                          return;
                        }

                        track('Empty State Action', {
                          text: 'Add Review',
                        });
                        setReviewFieldIsVisible(true);
                      },
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Skeleton height={240} />
            <Skeleton height={80} />
            <Skeleton height={24} />
          </>
        )}

        {tipEntertainerPopup}
        {isCurrentEntertainer && (
          <EditEntertainerPopup
            isVisible={isEditPopupVisible}
            onClose={() => {
              setIsEditPopupVisible(false);
            }}
          />
        )}
      </Screen.Content>
    </Screen>
  );
};
