import { useUserTalentQuery } from '@queries/UserQueries';

import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Screen } from '@components/layout/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { BookingOpportunityCard } from '@screens/entertainer/BookingOpportunityCard';
import { OpenBookingCard } from '@screens/promoter/ManagePromoterProfileScreen/OpenBookingCard';

import { SentBookingRequestCard } from './SentBookingRequestCard';

export const TalentOrganizerScreen = ({ back }) => {
  const { talentIsReady, talent } = useUserTalentQuery();

  return (
    <Screen
      name="Talent Organizer"
      headerProps={{ title: 'Talent Organizer', back: back ? { to: back } : undefined }}
    >
      <Screen.Content maxWidth={425}>
        <TitleToolbar text="Open Bookings" size="md" />
        {talentIsReady ? (
          <>
            {talent?.bookingOpportunities?.length ? (
              <HorizontalScroll
                items={talent?.bookingOpportunities?.map((bookingOpportunity) => (
                  <OpenBookingCard
                    key={bookingOpportunity._id}
                    bookingOpportunity={bookingOpportunity}
                  />
                ))}
              />
            ) : (
              <EmptyState
                title="No sent gig opportunities"
                text="You have not shared any gig opportunities. Use gig opportunities to share your event with all verified entertainers"
              />
            )}
          </>
        ) : (
          <Skeleton height={120} />
        )}
        <TitleToolbar text="Booking Requests" size="md" />
        {talentIsReady ? (
          <>
            {talent?.bookingRequests?.length ? (
              <HorizontalScroll
                items={talent?.bookingRequests?.map((bookingRequest) => (
                  <SentBookingRequestCard
                    key={bookingRequest._id}
                    bookingRequest={bookingRequest}
                  />
                ))}
              />
            ) : (
              <EmptyState
                title="No sent booking requests"
                text="You have not sent any booking requests to entertainers. You can send a booking request to any verified entertainer"
              />
            )}
          </>
        ) : (
          <Skeleton height={120} />
        )}
      </Screen.Content>
    </Screen>
  );
};
