import { Fab, ListInput } from 'framework7-react';
import { Input as F7Input } from 'framework7-react';
import { FC, useMemo, useState } from 'react';

import { isUndefined } from 'cypress/types/lodash';
import * as dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { omitBy } from 'lodash';

import { FeedEvent, useEventQuery, useUnauthorizedEventsQuery } from '@queries/EventQueries';
import { useSeriesByTagSlug } from '@queries/TagQueries';
import { useCurrentUser, useInterestQuery } from '@queries/UserQueries';

import { useEventFilters } from '@hooks/useEventFilters';

import { useLocation, useNavigate, useParams } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { VenueCard } from '@components/cards/VenueCard';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { DatePicker } from '@components/form/DatePicker';
import { DateRangePicker } from '@components/form/DateRangePicker';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { SeriesEventCard } from '../components/SeriesEventCard';

const CAN_ADD_EVENTS = false;

interface SeriesScreenProps {
  tagSlug: string;
}

const EventManagementSection: FC<{ tagSlug: string }> = ({ tagSlug }) => {
  const navigate = useNavigate();
  const [browseIsVisible, setBrowseIsVisible] = useState(false);
  const [searchParams, setSearchParams] = useState<
    Partial<{ search: string; startDate?: string; endDate?: string }>
  >({});

  const handleSearch = () => {
    const queryString = new URLSearchParams(searchParams).toString();

    navigate(`/noauth/series/${tagSlug}/events?${queryString}`);
  };

  return (
    <div className="position-relative">
      <div className="d-flex gap-2">
        <Button
          startIcon={<FontAwesomeIcon name="search" />}
          roundness="rounded"
          onClick={() => setBrowseIsVisible(true)}
        >
          Browse Events
        </Button>
        {CAN_ADD_EVENTS && (
          <Button
            startIcon={<FontAwesomeIcon name="plus" />}
            variant="outlined"
            color="neutral"
            roundness="rounded"
          >
            Add Event
          </Button>
        )}
      </div>

      <AnimatePresence>
        {browseIsVisible && (
          <motion.div
            className="position-absolute w-100 z-3 top-0 pb-4"
            style={{ background: '#000' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="d-grid gap-2">
              <div className="w-100">
                <Input
                  placeholder="All events"
                  onChange={(e) =>
                    setSearchParams((prevValue) => ({
                      ...prevValue,
                      search: e.target.value,
                    }))
                  }
                  onSubmit={handleSearch}
                />
              </div>
              <div className="w-100">
                <F7Input
                  type="datepicker"
                  placeholder="Any dates"
                  readonly
                  calendarParams={{ rangePicker: true, dateFormat: 'M d' }}
                  onCalendarChange={([startDateObj, endDateObj]) => {
                    const startDate = startDateObj
                      ? dayjs(startDateObj).format('YYYY-MM-DD')
                      : undefined;
                    const endDate = endDateObj ? dayjs(endDateObj).format('YYYY-MM-DD') : undefined;

                    setSearchParams((prevValue) => ({
                      ...prevValue,
                      startDate,
                      endDate,
                    }));
                  }}
                />
              </div>
              <div className="d-flex gap-2">
                <Button
                  roundness="rounded"
                  startIcon={<FontAwesomeIcon name="search" />}
                  onClick={handleSearch}
                >
                  Search Events
                </Button>
                <Button
                  roundness="rounded"
                  color="neutral"
                  variant="smooth"
                  startIcon={<FontAwesomeIcon name="times" />}
                  onClick={() => setBrowseIsVisible(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const EVENT_FILTERS = {
  all: { label: 'All' },
  'self-care': { label: 'Self-care sessions' },
  'crafts-and-making': { label: 'Crafts and making' },
  music: { label: 'Music' },
  'art-and-creativity': { label: 'Art and creativity' },
  'community-service': { label: 'Community service' },
  'holiday-markets': { label: 'Holiday markets' },
  'dining-experiences': { label: 'Dining experiences' },
  'general-gatherings': { label: 'General gatherings' },
};

type EventFilter = keyof typeof EVENT_FILTERS;

const FeaturedEventsSection: FC<{
  tagId: string | undefined;
  filterTags: { value: string; label: string }[] | undefined;
  cityId: string | undefined;
  onEventClick: (eventId: string) => void;
}> = ({ tagId = '', filterTags = [], cityId = '', onEventClick }) => {
  const [eventFilter, setEventFilter] = useState<string>('all');
  const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
    {
      cityId,
      tagIds: [tagId, ...(eventFilter !== 'all' ? [eventFilter] : [])],
      startDate: dayjs().format('YYYY-MM-DD'),
      featured: 'true',
      limit: '5',
      rsvpData: 'ignore',
    },
    Boolean(cityId && tagId),
  );

  return (
    <div className="mt-4">
      <TitleToolbar text="Featured Events ✨" size="md" />
      {!!filterTags.length && (
        <HorizontalScroll
          items={[
            <ChipRadioOptions
              value={eventFilter}
              onChange={(value) => setEventFilter(value)}
              options={[{ value: 'all', label: 'All Featured' }, ...filterTags].map(
                ({ value, label }) => ({
                  value,
                  label,
                  // showBadge: Boolean(value === 'suggested' && events?.suggested?.length),
                }),
              )}
            />,
          ]}
        />
      )}
      {eventsAreReady ? (
        <>
          {events.length ? (
            <HorizontalScroll
              items={events.map((event) => (
                <SeriesEventCard
                  key={event._id}
                  event={event}
                  onClick={() => onEventClick(event._id)}
                />
              ))}
            />
          ) : (
            <EmptyState title="No upcoming events" text="Check back later for more events" />
          )}
        </>
      ) : (
        <Skeleton height={360} />
      )}
    </div>
  );
};

const UpcomingEventsSection: FC<{
  tagId: string | undefined;
  cityId: string | undefined;
  onEventClick: (eventId: string) => void;
}> = ({ tagId = '', cityId = '', onEventClick }) => {
  const today = dayjs().format('YYYY-MM-DD');
  const { eventDateFilterOptions, eventFilters, updateDateFilter } = useEventFilters({
    dateRange: [today, today],
  });
  const [startDate, endDate] = eventFilters.dateRange;
  const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
    {
      startDate,
      endDate,
      tagIds: [tagId],
      cityId,
      limit: '5',
      rsvpData: 'ignore',
    },
    Boolean(cityId && tagId),
  );

  const handleDateFilterChange = (value: string) => {
    const selectedOption = eventDateFilterOptions.find((option) => option.value === value);

    if (selectedOption) {
      const [startDate, endDate] = selectedOption.getRange();
      // track('Update Date Filter', {
      //   value,
      //   startDate,
      //   endDate,
      // });
      updateDateFilter([startDate, endDate]);
    }
  };

  return (
    <div className="mt-4">
      <TitleToolbar text="Upcoming Events 🎉" size="md" />
      <HorizontalScroll
        items={[
          <ChipRadioOptions
            value={eventFilters.dateFilter}
            onChange={handleDateFilterChange}
            options={eventDateFilterOptions}
          />,
        ]}
      />
      {eventsAreReady ? (
        <>
          {events.length ? (
            <HorizontalScroll
              items={events.map((event) => (
                <SeriesEventCard
                  key={event._id}
                  event={event}
                  onClick={() => onEventClick(event._id)}
                />
              ))}
            />
          ) : (
            <EmptyState title="No upcoming events" text="Check back later for more events" />
          )}
        </>
      ) : (
        <Skeleton height={360} />
      )}
    </div>
  );
};

export const SeriesScreen = createScreenComponent<SeriesScreenProps>(
  ({ tagSlug, back = '/discover' }) => {
    const { series } = useSeriesByTagSlug(tagSlug);
    const navigate = useNavigate();

    const handleViewEvent = (eventId: string) => {
      navigate(`/events/${eventId}`);
    };

    return (
      <Screen
        name="Series Screen"
        hideToolbar
        headerProps={{ back: { to: back }, title: <Screen.GagglTitle /> }}
        // bottomToolbar={
        //   <Screen.BottomToolbar>
        //     <Button startIcon={<FontAwesomeIcon name="plus" />} roundness="rounded">
        //       Add Event
        //     </Button>
        //   </Screen.BottomToolbar>
        // }
      >
        <Screen.Content maxWidth={425}>
          <TitleToolbar text={series?.title} />

          <EventManagementSection tagSlug={tagSlug} />

          <div className="mt-4">
            <LinkBanner
              icon={
                <IconButton className="mx-auto" variant="smooth" color="neutral" size="lg">
                  <FontAwesomeIcon name="calendar" />
                </IconButton>
              }
              title="Looking for entertainers?"
              description={`Send gig opportunities to registered performers in ${series?.city?.name}`}
              onClick={() => navigate(`/noauth/open-bookings/${series?.city?._id}/create`)}
            />
          </div>

          <FeaturedEventsSection
            tagId={series?._id}
            filterTags={series?.filterTags?.map(({ _id: value, label }) => ({ value, label }))}
            cityId={series?.city?._id}
            onEventClick={handleViewEvent}
          />
          <UpcomingEventsSection
            tagId={series?._id}
            cityId={series?.city?._id}
            onEventClick={handleViewEvent}
          />
        </Screen.Content>
      </Screen>
    );
  },
);
