import { FC } from 'react';

import { FeedEvent } from '@queries/EventQueries';

import EventCard from '@components/cards/EventCard';

export const SeriesEventCard: FC<{ event: FeedEvent; onClick: (eventId: string) => void }> = ({
  event,
  onClick,
}) => {
  return <EventCard showPromoter={false} event={event} onClick={() => onClick(event._id)} />;
};
