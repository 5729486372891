import { FC } from 'react';

import { useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import { useCitiesQuery } from '@hooks/useCurrentUserLocation';

import { Popup } from '@components/layout/Popup/Popup';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { CreateEditEntertainerProfileForm } from '../CreateEntertainerProfileScreen';

export const EditEntertainerPopup: FC<{ isVisible: boolean; onClose: () => void }> = ({
  isVisible,
  onClose,
}) => {
  const { entertainer } = useCurrentEntertainerQuery();
  const { cities, citiesAreReady } = useCitiesQuery();

  if (!citiesAreReady) {
    return <></>;
  }

  return (
    <Popup isVisible={isVisible} onClose={onClose} height="full">
      <Popup.Content>
        <TitleToolbar text="Edit Profile" size="md" />
        <CreateEditEntertainerProfileForm
          cities={cities}
          entertainer={entertainer}
          onSave={onClose}
        />
      </Popup.Content>
    </Popup>
  );
};
