import { Input as F7Input } from 'framework7-react';
import { FC, useEffect, useMemo, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import * as dayjs from 'dayjs';
import { keyBy, sortBy, uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { useCritiqueQuestionsQuery, useCurrentUserGagglRankQuery } from '@queries/CritiqueQueries';
import { FeedEvent, useUserEventsQuery } from '@queries/EventQueries';
import {
  useCurrentUser,
  useFollowsQuery,
  useNotificationsQuery,
  useUserInterestsQuery,
  useUserWalletQuery,
} from '@queries/UserQueries';
import { useNearbyVenuesQuery } from '@queries/index';

import { useAskInAppMessageQueryMutation } from '@mutations/PostMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { useNavigate } from '@router/index';

import { findFirstAlphaCharacter } from '@utilities/findFirstAlphaCharacter';
import { isEnv } from '@utilities/isEnv';

import Button from '@components/buttons/Button';
import EventCard from '@components/cards/EventCard';
import { Chip } from '@components/chips/Chip';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { FormLabel } from '@components/form/FormLabel';
import { Input } from '@components/form/Input';
import { Textarea } from '@components/form/Textarea';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { PopoverWrapper, usePopoverRef } from '@components/layout/Popover/PopoverWrapper';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { overrideEventsForMarketing, overridePromoterForMarketing } from '@screens/PromoterScreen';

import { CityPicker } from './CityPicker';
import './HomeScreen.scss';
import { LeaderboardPopup } from './LeaderboardPopup';
import { ScoringPopup } from './ScoringPopup';

const EVENT_FILTER_TYPES = ['going', 'saved', 'suggested'] as const;
type EventFilter = (typeof EVENT_FILTER_TYPES)[number];

const EVENT_FILTERS: Record<
  EventFilter,
  {
    label: string;
    applyFn: (events: FeedEvent[]) => FeedEvent[];
  }
> = {
  going: { label: 'Going', applyFn: (events: FeedEvent[]) => events },
  saved: { label: 'Saved', applyFn: (events: FeedEvent[]) => events },
  suggested: { label: 'Suggested', applyFn: (events: FeedEvent[]) => events },
};

interface EventFilterEmptyStateProps {
  currentEventFilter: EventFilter;
  suggestedEventCount: number;
  onViewSuggested: () => void;
}
const EventFilterEmptyState: FC<EventFilterEmptyStateProps> = ({
  currentEventFilter,
  suggestedEventCount,
  onViewSuggested,
}) => {
  const text = `No one has shared any events for ${EVENT_FILTERS[
    currentEventFilter
  ].label.toLowerCase()}, but we have ${suggestedEventCount} suggested ${pluralize(
    'event',
    suggestedEventCount,
  )} for you.`;

  return (
    <EmptyState
      text={text}
      button={{
        text: `View Suggested ${pluralize('Event', suggestedEventCount)}`,
        onClick: onViewSuggested,
      }}
    />
  );
};

export const QuickPicksLinkBanner = ({
  id,
  disableLink,
}: {
  id?: string;
  disableLink?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <LinkBanner
      showHighlight
      id={id}
      icon={<FontAwesomeIcon name="comment" />}
      title="Quick Picks"
      description="Share fast and easy critiques"
      onClick={disableLink ? undefined : () => navigate('/quick-picks')}
    />
  );
};

export const UserGagglRankingCard: FC<{ showCritiqueButton?: boolean }> = ({
  showCritiqueButton,
}) => {
  const { currentUserGagglRank, refetchCurrentUserGagglRank } = useCurrentUserGagglRankQuery();
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const { walletIsReady, wallet } = useUserWalletQuery();
  const { openAlertDialog } = useDialog();

  const handleCashOut = () => {
    track('Cash Out', {
      total: wallet.total,
    });

    openAlertDialog({
      title: 'Not Available',
      text: 'Cashing out is not available at the moment, but we are working to get this feature to you ASAP.',
    });
  };

  const [leaderboardPopupIsVisible, setLeaderboardPopupIsVisible] = useState(false);
  const [criticScorePopupIsVisible, setCriticScorePopupIsVisible] = useState(false);

  useEffect(() => {
    refetchCurrentUserGagglRank();
  }, []);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex text-center gap-2">
            <div className="w-50">
              <div
                className="border rounded py-2"
                onClick={() => {
                  track('Click Rankings');
                  setLeaderboardPopupIsVisible(true);
                }}
              >
                <div>
                  <strong>Rank on Gaggl</strong>
                </div>
                <div>{currentUserGagglRank ? `#${currentUserGagglRank?.rank}` : '--'}</div>
              </div>
            </div>
            <div className="w-50">
              <div
                className="border rounded py-2"
                onClick={() => {
                  track('Click Critic Score');
                  setCriticScorePopupIsVisible(true);
                }}
              >
                <div>
                  <strong>
                    Critic Score <FontAwesomeIcon name="info-circle" />
                  </strong>
                </div>
                <div>
                  <span>{currentUserGagglRank ? currentUserGagglRank?.score ?? 0 : '--'}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <div className="fs-6">Your Wallet</div>
            <div className="fs-6">
              {walletIsReady ? (
                <strong>{`$${(wallet?.total / 100).toFixed(2)}`}</strong>
              ) : (
                <Skeleton height={24} />
              )}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button
            roundness="rounded"
            color="neutral"
            variant="smooth"
            disabled={!wallet?.total}
            onClick={handleCashOut}
          >
            Cash Out
          </Button>
        </Card.Footer>

        {showCritiqueButton && (
          <Card.Footer>
            <Button
              color="neutral"
              endIcon={<FontAwesomeIcon name="chevron-right" />}
              onClick={() => navigate('/critique')}
              disabled={false}
            >
              Share more critiques
            </Button>
          </Card.Footer>
        )}
      </Card>

      <LeaderboardPopup
        isVisible={leaderboardPopupIsVisible}
        onClose={() => setLeaderboardPopupIsVisible(false)}
      />
      <ScoringPopup
        isVisible={criticScorePopupIsVisible}
        onClose={() => setCriticScorePopupIsVisible(false)}
      />
    </>
  );
};

export const UserInterestsSection: FC<{ back: string }> = ({ back }) => {
  const { userInterestsAreReady, userInterests } = useUserInterestsQuery();
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const userInterestRows = useMemo(() => {
    return userInterests
      ?.sort((a, b) => a.title.localeCompare(b.title))
      ?.reduce(
        (rows, interest) => {
          const rowIndex = rows[1].charLength > rows[2].charLength ? 2 : 1;

          rows[rowIndex].items.push(interest);
          rows[rowIndex].charLength += interest.title.length;

          return rows;
        },
        {
          1: {
            items: [],
            charLength: 0,
          },
          2: {
            items: [],
            charLength: 0,
          },
        },
      );
  }, [userInterests]);

  return (
    <>
      <TitleToolbar
        text="Your Interests 👀"
        size="md"
        action={
          !!userInterests?.length && (
            <Button
              variant="flat"
              onClick={() =>
                navigate('/interests', {
                  state: {
                    back,
                  },
                })
              }
              disabled={false}
            >
              Edit
            </Button>
          )
        }
      />
      {userInterestsAreReady ? (
        <>
          {userInterests?.length ? (
            <HorizontalScroll
              items={[
                <div className="interests-section">
                  {Object.values(userInterestRows).map((row, i) => (
                    <div key={i} className="d-flex">
                      {row.items.map((interest) => (
                        <div key={interest._id} className="interests-section_btn-wrapper">
                          <Button
                            variant="default"
                            color="default"
                            roundness="rounded"
                            endIcon={<FontAwesomeIcon name="chevron-right" />}
                            disabled={false}
                            onClick={() =>
                              navigate(`/search`, {
                                state: { searchValue: interest.title, back },
                              })
                            }
                          >
                            {interest.title}
                          </Button>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>,
              ]}
            />
          ) : (
            <EmptyState
              title="You haven't selected any interests yet."
              text="Add your interests to get more personalized recommendations."
              button={{
                text: 'Add Interests',
                onClick: () =>
                  navigate('/interests', {
                    state: {
                      back,
                    },
                  }),
              }}
            />
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <Skeleton height={90} />
        </div>
      )}
    </>
  );
};

export const UserEventsSection: FC<{ back: string }> = ({ back }) => {
  const { track } = useAnalytics();

  const { eventsAreReady, events } = useUserEventsQuery();

  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const handleViewEvent = (eventId: string) => {
    navigate(`/events/${eventId}`);
  };

  const [eventFilter, setEventFilter] = useState<EventFilter>('suggested');

  useEffect(() => {
    const initialFilter = (['going', 'saved', 'suggested'] as EventFilter[]).find(
      (filter) => events?.[filter]?.length,
    );
    setEventFilter(initialFilter ?? 'suggested');
  }, [events]);

  const filteredEvents = useMemo(() => {
    return events?.[eventFilter] ?? [];
  }, [events, eventFilter]);

  return (
    <>
      <TitleToolbar text="Upcoming Events 🎉" size="md" />
      {eventsAreReady ? (
        <HorizontalScroll
          items={[
            <ChipRadioOptions
              value={eventFilter}
              onChange={(value: any) => setEventFilter(value)}
              options={Object.entries(EVENT_FILTERS).map(([value, { label }]) => ({
                value,
                label,
                showBadge: Boolean(value === 'suggested' && events?.suggested?.length),
              }))}
            />,
          ]}
        />
      ) : (
        <div className="mb-3">
          <Skeleton height={28} />
        </div>
      )}

      {eventsAreReady ? (
        <>
          {eventFilter === 'suggested' ? (
            <>
              {filteredEvents.length ? (
                <EmptyState
                  title="Event Suggestions"
                  text={`We have ${filteredEvents.length} suggested ${pluralize(
                    'event',
                    filteredEvents.length,
                  )} for you.`}
                  button={{
                    text: `View Suggested ${pluralize('Event', filteredEvents.length)}`,
                    onClick: () =>
                      navigate('/events/suggested', {
                        state: {
                          back,
                        },
                      }),
                  }}
                />
              ) : (
                <EmptyState
                  title="Event Suggestions"
                  text={`We don't have any suggested events for you right now. Add more interests or follow more venues and promoters to help us find you more suggestions.`}
                  button={{
                    text: `Manage Interests`,
                    onClick: () => navigate('/interests'),
                  }}
                />
              )}
            </>
          ) : (
            <>
              {filteredEvents.length ? (
                <HorizontalScroll
                  items={filteredEvents.map((event) => (
                    <EventCard
                      showPromoter={false}
                      key={event._id}
                      event={event}
                      onClick={() => handleViewEvent(event._id)}
                      showFooter={currentUser?.isSuperAdmin}
                    />
                  ))}
                />
              ) : (
                <EmptyState
                  title="Find Nearby Events"
                  text="Start saving and rsvping to events to see them here."
                  button={{
                    text: `Find Events Nearby`,
                    onClick: () =>
                      navigate('/discover', {
                        animate: false,
                      }),
                  }}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <Skeleton height={200} />
        </div>
      )}
    </>
  );
};

export const HomeScreen: FC<{}> = () => {
  const { refetchEvents } = useUserEventsQuery();
  const { refetchCurrentUserGagglRank } = useCurrentUserGagglRankQuery();
  const { refetchNotifications } = useNotificationsQuery();
  const {
    critiqueQuestions = [],
    critiqueQuestionsAreReady,
    refetchCritiqueQuestions,
  } = useCritiqueQuestionsQuery();
  const { currentCity } = useCurrentUserCity();

  const handleReenter = async () => {
    refetchNotifications();
    refetchCurrentUserGagglRank();
    refetchCritiqueQuestions();
    refetchEvents();
  };

  return (
    <Screen
      name="Home Screen"
      headerProps={{
        title: currentCity?.name ? <CityPicker /> : undefined,
      }}
      onReenter={handleReenter}
    >
      <Screen.Content onPullToRefresh={handleReenter}>
        <TitleToolbar text={`Hey sis! 💃🏽`} className="title-toolbar-welcome" />
        {/* <TitleToolbar text="Your Rankings 🏆" size="md" /> */}

        {process.env.REACT_APP_ENV === 'dev' && (
          <>
            <FormLabel text="Referral Code" />
            <Input />
          </>
        )}

        {critiqueQuestionsAreReady ? (
          <>
            {!!critiqueQuestions.length && (
              <>
                <div className="mt-2" />
                <QuickPicksLinkBanner id="home-screen-quick-picks" />
              </>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Skeleton height={200} />
          </div>
        )}

        <UserGagglRankingCard showCritiqueButton />

        <UserEventsSection back={'TODO'} />

        <UserInterestsSection back={'TODO'} />
      </Screen.Content>
    </Screen>
  );
};
