import { FC, ReactNode, useMemo, useState } from 'react';

import { use } from 'chai';

import { Notification, NotificationType, useNotificationsQuery } from '@queries/UserQueries';

import {
  useMarkNotificationAsReadMutation,
  useMarkNotificationsAsSeenMutation,
} from '@mutations/UserMutations';

import { useAnalytics } from '@hooks/useAnalytics';
import { useCanInstallIOSApp } from '@hooks/useCanInstallIOSApp';
import { useCapacitorUpdateAvailable } from '@hooks/useCapacitorUpdateAvailable';
import { useDevice } from '@hooks/useDevice';
import { usePWA } from '@hooks/usePWA';

import { NavigateFunction, useNavigate } from '@router/index';

import dayjs from '@utilities/dayjs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { Input } from '@components/form/Input';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { LetterAvatar } from '@components/layout/LetterAvatar/LetterAvatar';
import { List, ListItemProps } from '@components/layout/List/List';
import { Popup } from '@components/layout/Popup/Popup';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import './NotificationsScreen.scss';

const NOTIFICATION_ACTION_RECORD: Record<
  NotificationType,
  {
    label: string;
    to: (notification: Notification) => string;
  }
> = {
  suggestedEvents: { label: 'View events', to: () => '/events/suggested' },
};

const NotificationIcon: FC<{
  isUnread: boolean;
  avatarLetter: string;
}> = ({ isUnread, avatarLetter }) => {
  return (
    <div className="notification-media">
      {isUnread && (
        <div className="notification-unread-icon">
          <FontAwesomeIcon name="circle" />
        </div>
      )}
      <LetterAvatar letter={avatarLetter} />
    </div>
  );
};

export interface NotificationListItem {
  isUnread: boolean;
  avatarLetter: string;
  title: string;
  subTitle: string;
  endIcon?: ReactNode;
  onClick: () => void;
  noChevron?: boolean;
}

export const NoticationsList: FC<{
  listItems: NotificationListItem[];
}> = ({ listItems }) => {
  return (
    <List
      className="notification-list"
      listItems={listItems.map((listItem) => ({
        startIcon: (
          <NotificationIcon isUnread={listItem.isUnread} avatarLetter={listItem.avatarLetter} />
        ),
        title: listItem.title,
        subTitle: listItem.subTitle,
        endIcon: listItem.endIcon,
        onClick: listItem.onClick,
        noChevron: listItem.noChevron,
      }))}
    />
  );
};

export const NotificationsScreen: FC<{ back?: string }> = ({ back }) => {
  const {
    notifications = [],
    refetchNotifications,
    notificationsAreReady,
  } = useNotificationsQuery();
  const { track } = useAnalytics();
  const { markNotificationsAsSeenAsync } = useMarkNotificationsAsSeenMutation();
  const { markNotificationAsReadAsync } = useMarkNotificationAsReadMutation();
  const navigate = useNavigate();
  const { canInstallIOSApp } = useCanInstallIOSApp();
  const { isAndroidBrowser } = useDevice();
  const { canInstallPWA, promptPWAInstall } = usePWA();
  const { isCapacitorUpdateAvailable, updateApp } = useCapacitorUpdateAvailable();
  const [isAndroidBetaPopupOpen, setIsAndroidBetaPopupOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleJoinAndroidBeta = () => {
    track('Join Android Beta Clicked');
    // openExternalUrl('https://play.google.com/apps/internaltest/4701327376062236072');
    setIsAndroidBetaPopupOpen(true);
  };

  const handleSubmitEmail = () => {
    track('Android Beta Email Submitted', { email });
    setSubmitted(true);

    setTimeout(() => {
      setSubmitted(false);
    }, 5000);
  };

  const handleOpenAppStore = () => {
    track('iOS Install Clicked');
    openExternalUrl('https://apps.apple.com/us/app/gaggl-local-lgbtq-events/id1593891795');
  };

  const notificationsList = useMemo<NotificationListItem[]>(() => {
    return [
      ...(isCapacitorUpdateAvailable
        ? [
            {
              isUnread: true,
              avatarLetter: 'G',
              title: 'App update available',
              subTitle: 'a few seconds ago',
              onClick: updateApp,
              noChevron: true,
            },
          ]
        : []),
      // ...(isAndroidBrowser
      //   ? [
      //       {
      //         isUnread: true,
      //         avatarLetter: 'G',
      //         title: 'Join the Android app beta to help us get this in the Play Store',
      //         subTitle: 'a few seconds ago',
      //         onClick: handleJoinAndroidBeta,
      //         noChevron: true,
      //       },
      //     ]
      //   : []),
      ...(canInstallPWA
        ? [
            {
              isUnread: true,
              avatarLetter: 'G',
              title: 'Install the app for a better experience',
              subTitle: 'a few seconds ago',
              onClick: promptPWAInstall,
              noChevron: true,
            },
          ]
        : []),
      ...(canInstallIOSApp
        ? [
            {
              isUnread: true,
              avatarLetter: 'G',
              title: 'Install the app for a better experience',
              subTitle: 'a few seconds ago',
              onClick: handleOpenAppStore,
              noChevron: true,
            },
          ]
        : []),
      ...notifications.map((notification) => {
        const timeAgo = dayjs(notification.sentAt).fromNow();
        const action = NOTIFICATION_ACTION_RECORD[notification.type];

        const handleClick = async () => {
          navigate(action?.to?.(notification) ?? notification.to);

          if (!notification.readAt) {
            await markNotificationAsReadAsync({ notificationId: notification._id });
          }
        };

        return {
          isUnread: !notification.readAt,
          avatarLetter: 'G',
          title: notification.text,
          subTitle: timeAgo,
          onClick: handleClick,
          noChevron: true,
        };
      }),
    ];
  }, [notifications]);

  const handleEnter = () => {
    const hasUnseenNotifications = !!notifications.filter(({ seenAt }) => !seenAt).length;

    if (hasUnseenNotifications) {
      setTimeout(() => {
        markNotificationsAsSeenAsync();
      }, 5000);
    }
  };

  const handleReenter = () => {
    refetchNotifications();
  };

  return (
    <Screen
      name="Notifications Screen"
      headerProps={{
        title: 'Notifications',
        back: back ? { to: back } : undefined,
      }}
      onEnter={handleEnter}
      onReenter={handleReenter}
    >
      <Screen.Content>
        <TitleToolbar text="Recent notifications 🔔" size="md" />
        {notificationsAreReady ? (
          <>
            {notificationsList.length ? (
              <NoticationsList listItems={notificationsList} />
            ) : (
              <EmptyState
                title="All caught up! 🙌🏽"
                text="You have no new notifications. Try spillin' some tea to get things going! 😏"
                button={{
                  text: 'Spill some tea',
                  onClick: () =>
                    navigate('/community', {
                      animate: false,
                    }),
                }}
              />
            )}
          </>
        ) : (
          <div className="grid gap-2">
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
            <Skeleton height={60} />
          </div>
        )}
      </Screen.Content>
      <Popup
        isVisible={isAndroidBetaPopupOpen}
        onClose={() => {
          setIsAndroidBetaPopupOpen(false);
        }}
      >
        <Popup.Content>
          <TitleToolbar className="mb-0" text="Join Android Beta" size="md" />
          <p>
            Thank you for your interest in joining the Android beta. Google requires we have at
            least 20 folx signed up before we can make the app available to the public. To get
            started, we need to add the email address that you use with your Google Play Store
            account to our list of testers.
          </p>
          <div className="d-grid gap-2">
            <Input
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Button disabled={!email || submitted} roundness="rounded" onClick={handleSubmitEmail}>
              {submitted ? 'Submitted' : 'Sign Up'}
            </Button>
          </div>
        </Popup.Content>
      </Popup>
    </Screen>
  );
};
