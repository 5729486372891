import { Badge, Button, Segmented } from 'framework7-react';
import { FC } from 'react';

interface ButtonFilterProps {
  className?: string;
  options: { value: string; label: string; badgeCount?: string | number }[];
  selected: string;
  truncate?: boolean;
  onChange: (updatedSelected: string) => void;
}

export const ButtonFilter: FC<ButtonFilterProps> = ({
  className,
  options,
  selected,
  truncate,
  onChange,
}) => {
  return (
    <Segmented strong tag="p" className={className}>
      {options.map((option) => (
        <Button
          key={option.value}
          active={selected === option.value}
          onClick={() => onChange(option.value)}
        >
          <div className="d-flex gap-1 align-items-center justify-content-center w-100 mx-auto">
            <div className={truncate ? 'text-truncate' : ''}>{option.label}</div>
            {!!option.badgeCount && <Badge color="red">{option.badgeCount}</Badge>}
          </div>
        </Button>
      ))}
    </Segmented>
  );
};
