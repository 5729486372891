import { FC, useMemo, useState } from 'react';

import * as dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { EntertainerBookingRequest, useCurrentEntertainerQuery } from '@queries/EntertainerQueries';

import {
  RespondToBookingRequestMutationPayload,
  useRespondToBookingRequestMutation,
} from '@mutations/EntertainerMutations';

import { useNavigate, useParams } from '@router/index';

import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';
import { openExternalUrl } from '@utilities/openExternalUrl';

import Button from '@components/buttons/Button';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { LineAwesomeIcon } from '@components/icons/LineAwesomeIcon';
import Card from '@components/layout/Card/Card';
import { PopoverWrapper } from '@components/layout/Popover/PopoverWrapper';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

const StatusActionButton = ({ bookingRequest }: { bookingRequest: EntertainerBookingRequest }) => {
  const [status, setStatus] = useState(bookingRequest.status);
  const { isSubmitting, respondToBookingRequestAsync } = useRespondToBookingRequestMutation();
  const handleUpdateBookingRequestInterest = async ({
    status,
  }: {
    status: RespondToBookingRequestMutationPayload['status'];
  }) => {
    setStatus(status);
    await respondToBookingRequestAsync({
      bookingRequestId: bookingRequest._id,
      status,
    });
  };

  if (['confirmed', 'rejected'].includes(status)) {
    return (
      <Button disabled roundness="rounded" variant="outlined" color="neutral">
        {capitalize(status)}
      </Button>
    );
  }

  return (
    <div className="d-flex gap-2 w-100">
      <div className="w-50">
        <Button
          disabled={isSubmitting}
          noWrap
          roundness="rounded"
          color="neutral"
          variant={status === 'notInterested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={status === 'notInterested' ? 'times-circle' : 'circle'}
            />
          }
          onClick={() =>
            handleUpdateBookingRequestInterest({
              status: 'notInterested',
            })
          }
        >
          Not interested
        </Button>
      </div>
      <div className="w-50">
        <Button
          noWrap
          roundness="rounded"
          disabled={isSubmitting}
          variant={status === 'interested' ? 'default' : 'outlined'}
          startIcon={
            <FontAwesomeIcon
              faStyle="far"
              name={status === 'interested' ? 'check-circle' : 'circle'}
            />
          }
          onClick={() =>
            handleUpdateBookingRequestInterest({
              status: 'interested',
            })
          }
        >
          Interested
        </Button>
      </div>
    </div>
  );
};

const IS_DEMO = false;
const MOCK_BOOKING_REQUEST: EntertainerBookingRequest = {
  _id: '1234567890',
  status: 'pending',
  requestedBy: 'Trans Awareness Organization',
  createdAt: '2024-07-07T12:00:00Z',
  city: {
    _id: '0987654321',
    name: 'Denver',
  },
  entertainer: {
    _id: '0987654321',
    title: 'Aria Hung',
  },
  details: {
    startDate: '2024-09-01',
    startTime: '19:00',
    venue: { title: 'Pride Center' },
  },
  contact: {
    name: 'Alex Johnson',
    phone: '555-1234',
    email: 'alex.johnson@example.com',
  },
  performance: {
    duration: '15 minutes',
    type: 'Campy drag performance highlighting the beauty of trans folks',
    specialRequests: 'Include a tribute to Marsha P. Johnson',
  },
  technical: {
    soundSystem: 'Yes, microphone and speakers',
    dressingRoom: 'Yes, we will repurpose an office as a dressing room',
    lightingSetup: 'Yes, Standard stage lighting with some spotlights',
  },
};

export const ManageEntertainerBookingRequestScreen: FC<{}> = () => {
  const { bookingRequestId } = useParams<{ bookingRequestId?: string }>();
  const navigate = useNavigate();
  const { entertainerIsReady, entertainer, bookingRequests } = useCurrentEntertainerQuery();
  const bookingRequest = useMemo<EntertainerBookingRequest>(() => {
    if (IS_DEMO) {
      return MOCK_BOOKING_REQUEST;
    }
    return bookingRequests.find(({ _id }) => _id === bookingRequestId);
  }, [bookingRequests, bookingRequestId]);

  return (
    <Screen
      name="Booking Request Details Screen"
      headerProps={{
        back: { to: '/manage/entertainer' },
        title: 'Booking Request',
      }}
      bottomToolbar={
        <Screen.BottomToolbar>
          {bookingRequest && <StatusActionButton bookingRequest={bookingRequest} />}
        </Screen.BottomToolbar>
      }
    >
      <Screen.Content>
        {entertainerIsReady ? (
          <>
            {bookingRequest && (
              <div className="grid">
                <div>
                  <TitleToolbar
                    className="title-toolbar-welcome"
                    text={bookingRequest.contact.name}
                    action={
                      <Button
                        roundness="rounded"
                        variant="outlined"
                        color="neutral"
                        onClick={() =>
                          navigate(
                            `/chat/entertainer/${entertainer._id}/user/${bookingRequest?.requestedBy}`,
                          )
                        }
                      >
                        Message
                      </Button>
                    }
                  />
                  <div className="d-flex gap-2">
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="clock" />
                      </span>
                      {dayjs(bookingRequest.details.startDate).format('ddd, MMM D, YYYY')}
                    </div>
                    <div>
                      <span className="me-1">
                        <LineAwesomeIcon name="map-marker" />
                      </span>
                      {`${bookingRequest.details.venue.title}, ${bookingRequest.city.name}`}
                    </div>
                  </div>
                  <div
                    className="pb-2"
                    style={{
                      borderBottom: '1px solid rgba(255,255,255,.5)',
                    }}
                  >
                    <p className="mt-1">
                      <strong>Duration: </strong>
                      {bookingRequest.performance.duration}
                    </p>
                    {mapStringToParagraphs(bookingRequest.performance.type)}
                    {bookingRequest.performance.additionalQuestions &&
                      mapStringToParagraphs(bookingRequest.performance.additionalQuestions)}
                  </div>

                  <TitleToolbar text="Special Requests" size="md" className="mb-0" />
                  <div
                    className="pb-2"
                    style={{
                      borderBottom: '1px solid rgba(255,255,255,.5)',
                    }}
                  >
                    {mapStringToParagraphs(bookingRequest.performance.specialRequests)}
                  </div>

                  <TitleToolbar text="Technical Requirements" size="md" className="mb-0" />
                  <div style={{}}>
                    <p>
                      <strong>Has Dressing Room: </strong>
                      {bookingRequest.technical.dressingRoom}
                    </p>
                    <p>
                      <strong>Has Sound System: </strong>
                      {bookingRequest.technical.soundSystem}
                    </p>
                    <p>
                      <strong>Has Lighting Setup: </strong>
                      {bookingRequest.technical.lightingSetup}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <Skeleton height={200} />
        )}
      </Screen.Content>
    </Screen>
  );
};
