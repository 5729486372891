import {
  Block,
  BlockTitle,
  Popup as F7Popup,
  NavRight,
  NavTitle,
  Navbar,
  Page,
  f7ready,
} from 'framework7-react';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Popup as PopupType } from 'framework7/types';

import { useAnalytics } from '@hooks/useAnalytics';

import IconButton from '@components/buttons/IconButton';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';

import { ErrorBoundary } from '../ErrorBoundary';
import './Popup.scss';

const PageContent = () => (
  <Page>
    <div slot="fixed" className="swipe-handler"></div>
    <BlockTitle large>Hello!</BlockTitle>
    <Block strongIos outlineIos>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus mauris leo, eu
        bibendum neque congue non. Ut leo mauris, eleifend eu commodo a, egestas ac urna. Maecenas
        in lacus faucibus, viverra ipsum pulvinar, molestie arcu. Etiam lacinia venenatis dignissim.
        Suspendisse non nisl semper tellus malesuada suscipit eu et eros. Nulla eu enim quis quam
        elementum vulputate. Mauris ornare consequat nunc viverra pellentesque. Aenean semper eu
        massa sit amet aliquam. Integer et neque sed libero mollis elementum at vitae ligula.
        Vestibulum pharetra sed libero sed porttitor. Suspendisse a faucibus lectus.
      </p>
      <p>
        Duis ut mauris sollicitudin, venenatis nisi sed, luctus ligula. Phasellus blandit nisl ut
        lorem semper pharetra. Nullam tortor nibh, suscipit in consequat vel, feugiat sed quam. Nam
        risus libero, auctor vel tristique ac, malesuada ut ante. Sed molestie, est in eleifend
        sagittis, leo tortor ullamcorper erat, at vulputate eros sapien nec libero. Mauris dapibus
        laoreet nibh quis bibendum. Fusce dolor sem, suscipit in iaculis id, pharetra at urna.
        Pellentesque tempor congue massa quis faucibus. Vestibulum nunc eros, convallis blandit dui
        sit amet, gravida adipiscing libero.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus mauris leo, eu
        bibendum neque congue non. Ut leo mauris, eleifend eu commodo a, egestas ac urna. Maecenas
        in lacus faucibus, viverra ipsum pulvinar, molestie arcu. Etiam lacinia venenatis dignissim.
        Suspendisse non nisl semper tellus malesuada suscipit eu et eros. Nulla eu enim quis quam
        elementum vulputate. Mauris ornare consequat nunc viverra pellentesque. Aenean semper eu
        massa sit amet aliquam. Integer et neque sed libero mollis elementum at vitae ligula.
        Vestibulum pharetra sed libero sed porttitor. Suspendisse a faucibus lectus.
      </p>
      <p>
        Duis ut mauris sollicitudin, venenatis nisi sed, luctus ligula. Phasellus blandit nisl ut
        lorem semper pharetra. Nullam tortor nibh, suscipit in consequat vel, feugiat sed quam. Nam
        risus libero, auctor vel tristique ac, malesuada ut ante. Sed molestie, est in eleifend
        sagittis, leo tortor ullamcorper erat, at vulputate eros sapien nec libero. Mauris dapibus
        laoreet nibh quis bibendum. Fusce dolor sem, suscipit in iaculis id, pharetra at urna.
        Pellentesque tempor congue massa quis faucibus. Vestibulum nunc eros, convallis blandit dui
        sit amet, gravida adipiscing libero.
      </p>
    </Block>
  </Page>
);

interface PopupProps {
  isVisible: boolean;
  height?: 'half' | '3quarters' | 'full';
  title?: string;
  onClose: () => void;
  disableClose?: boolean;
}

export const Popup = ({
  isVisible: controlledIsVisible,
  onClose,
  title,
  height = 'half',
  disableClose,
  children,
}: PropsWithChildren<PopupProps>) => {
  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef<any>();
  const popupRef = useRef<PopupType.Popup>();
  const { track } = useAnalytics();

  useEffect(() => {
    setIsVisible(controlledIsVisible);
  }, [controlledIsVisible]);

  useEffect(() => {
    f7ready((f7) => {
      popupRef.current = f7.popup.create({
        el: divRef.current,
        swipeToClose: true,
        swipeHandler: '.swipe-handler',
        on: {
          swipeClose: () => {
            track('Popup Swipe Closed');
          },
          closed: () => {
            onClose();
          },
        },
      });
    });
  }, []);

  useEffect(() => {
    if (popupRef.current) {
      if (isVisible) {
        popupRef.current.open();
      } else {
        popupRef.current.close();
      }
    }
  }, [isVisible]);

  return (
    <div
      ref={divRef}
      className={`popup popup-height-${height}`}
      data-close-on-escape={disableClose ? undefined : 'true'}
      data-swipe-to-close={disableClose ? undefined : 'to-bottom'}
    >
      <Page>
        {height === 'full' ? (
          <Navbar>
            {title && <NavTitle>{title}</NavTitle>}
            {!disableClose && (
              <NavRight>
                <IconButton
                  color="neutral"
                  variant="smooth"
                  onClick={() => {
                    popupRef.current.close();
                  }}
                >
                  <FontAwesomeIcon name="times" />
                </IconButton>
              </NavRight>
            )}
          </Navbar>
        ) : (
          <div slot="fixed" className="swipe-handler">
            <div />
          </div>
        )}
        <div>{children}</div>
      </Page>
    </div>
  );
};

const PopupScrollabelContent: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Page>
      <div slot="fixed" className="swipe-handler">
        <div />
      </div>
      <div className="popup-content">{children}</div>
    </Page>
  );
};

Popup.ScrollableContent = PopupScrollabelContent;

const PopupContent: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="popup-content">
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};

Popup.Content = PopupContent;
