import * as Sentry from '@sentry/react';

import mixpanel from 'mixpanel-browser';

import API from '@api/API';

export const sendEntertainerDetailsPageView = async (entertainerId: string) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const fbclid = urlParams.get('fbclid') || '';
    const clientUserAgent = window.navigator.userAgent ?? '';
    const deviceId = mixpanel.cookie.props.$device_id;

    if (deviceId && process.env.REACT_APP_ENV !== 'dev') {
      await API.post(`/v1/entertainers/${entertainerId}/view`, {
        entertainerId,
        deviceId,
        fbclid,
        clientUserAgent,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};
