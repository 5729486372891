import { FC, MouseEventHandler } from 'react';

import {
  Entertainer,
  useCurrentEntertainerQuery,
  useFeaturedEntertainersQuery,
} from '@queries/EntertainerQueries';

import { useTipEntertainer } from '@hooks/EntertainerHooks/useTipEntertainer';
import { useAnalytics } from '@hooks/useAnalytics';

import { useNavigate, useRouter } from '@router/index';

import Button from '@components/buttons/Button';
import { SendEntertainerBadgeButton } from '@components/buttons/SendEntertainerBadgeButton';
import { AsyncImage } from '@components/layout/AsyncImg';
import Card from '@components/layout/Card/Card';
import { useDialog } from '@components/layout/Dialog/useDialog';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { VenmoLogo } from '../../../assets/VenmoLogo';

const FeaturedEntertainerCard: FC<{ entertainer: Entertainer; back: string }> = ({
  entertainer,
  back,
}) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { handleVenmoEntertainer } = useTipEntertainer();
  const { openAlertDialog } = useDialog();

  const image = entertainer.avatar || entertainer.featuredMedia?.[0]?.image;
  const imageSize = 4 * 18;

  const handleClick = () => {
    track('Click Featured Entertainer Card');
    navigate(`/noauth/entertainer/${entertainer.bookingHandle}`, {
      state: {
        back,
      },
    });
  };

  const handleBook: MouseEventHandler = (e) => {
    e.stopPropagation();
    track('Click Book Featured Entertainer');
    navigate(`/noauth/book/${entertainer.bookingHandle}`, {
      state: {
        back,
      },
    });
  };

  const handleTip: MouseEventHandler = (e) => {
    track('Click Tip Featured Entertainer');
    if (!entertainer?.contact?.venmo) {
      openAlertDialog({
        title: 'No Venmo',
        text: `This entertainer has not added their Venmo handle for tipping, but you can still book them for an event with the "Book" button.`,
      });
      return;
    }
    handleVenmoEntertainer({
      entertainerId: entertainer._id,
      venmo: entertainer?.contact?.venmo,
    });
  };

  return (
    <Card>
      <div className="d-flex gap-2 p-3 align-items-center">
        <div
          style={{
            position: 'relative',
            width: imageSize,
            height: imageSize,
          }}
          onClick={handleClick}
        >
          {/* <div
            className="d-flex align-items-center justify-content-center"
            style={{
              display: 'none',
              position: 'absolute',
              height: 24,
              width: 24,
              top: -12,
              left: -12,
              borderRadius: 500,
            }}
          >
            <div>#1</div>
          </div> */}
          <div style={{ borderRadius: 16, overflow: 'hidden', width: '100%', height: '100%' }}>
            <AsyncImage
              src={image}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              placeholder={
                <div
                  style={{ width: '100%', height: '100%', background: 'rgba(255,255,255,.2)' }}
                />
              }
            />
          </div>
        </div>
        <div>
          <h3 className="mt-0 mb-2" onClick={handleClick}>
            {entertainer.title}
          </h3>
          <div className="d-flex gap-2">
            <div>
              <Button
                className="py-0"
                style={{ height: 36, opacity: entertainer?.contact?.venmo ? 1 : 0.2 }}
                roundness="rounded"
                color={entertainer?.contact?.venmo ? 'primary' : 'neutral'}
                startIcon={
                  <div style={{ width: 18, height: 18 }}>
                    <VenmoLogo />
                  </div>
                }
                onClick={handleTip}
              >
                Send a Tip
              </Button>
            </div>
            <div>
              <Button
                className="py-0"
                style={{ height: 36 }}
                roundness="rounded"
                onClick={handleBook}
              >
                Book
              </Button>
            </div>
            {/* <SendEntertainerBadgeButton
              buttonProps={{ color: 'neutral', variant: 'smooth' }}
              entertainerId={entertainer._id}
            /> */}
          </div>
        </div>
      </div>
    </Card>
  );
};

export const FeaturedEntertainersSection: FC<{ cityId: string; back?: string }> = ({
  cityId,
  back,
}) => {
  const { entertainer } = useCurrentEntertainerQuery();
  const { track } = useAnalytics();
  const { navigate } = useRouter();

  const { featuredEntertainers = [], featuredEntertainersAreReady } = useFeaturedEntertainersQuery(
    cityId,
    'dragPerformer',
  );

  const { openAlertDialog } = useDialog();

  const handleFeaturedInstructions = () => {
    track('Click Featured Instructions');
    openAlertDialog({
      title: 'How to get featured',
      text: `The most active entertainers are featured for easier discovery and booking. To be featured, add featured looks and share badges frequently and add critiques to other entertainers.`,
    });
  };

  return (
    <>
      <TitleToolbar
        className="mb-1"
        text="Featured Entertainers"
        size="sm"
        action={
          entertainer && (
            <Button
              variant="smooth"
              color="neutral"
              size="sm"
              className="px-3"
              roundness="rounded"
              onClick={handleFeaturedInstructions}
            >
              How do I get featured?
            </Button>
          )
        }
      />
      {/* <p className="mt-0">Send a badge to recognize someone's recent moment in the spotlight.</p> */}
      {featuredEntertainersAreReady ? (
        <>
          {featuredEntertainers.length ? (
            <HorizontalScroll
              items={featuredEntertainers.map((entertainer) => (
                <FeaturedEntertainerCard
                  key={entertainer._id}
                  entertainer={entertainer}
                  back={back}
                />
              ))}
            />
          ) : (
            <EmptyState
              title="No featured entertainers"
              text="The most active entertainers are featured for easier discovery and booking."
            />
          )}
        </>
      ) : (
        <Skeleton height={148} />
      )}
    </>
  );
};
