import { useQuery } from '@tanstack/react-query';

import { useAppContext } from '@contexts/AppContext';

import API from '@api/API';

import { useCurrentUserCity, useCurrentUserLocation } from '@hooks/useCurrentUserLocation';

import { CritiqueData, UserCritique } from './CritiqueQueries';

interface Series {
  _id: string;
  title: string;
  city: {
    _id: string;
    name: string;
  };
  filterTags: { _id: string; label: string }[];
}

export const useSeriesByTagSlug = (tagSlug: string) => {
  const { data, isFetched, refetch } = useQuery({
    queryKey: ['series', tagSlug],
    queryFn: () => API.get<{ series: Series }>(`/v1/series/${tagSlug}`),
    enabled: !!tagSlug,
  });

  return { series: data?.series, isFetched, refetch };
};
