import { Fab, ListInput, f7 } from 'framework7-react';
import { Input as F7Input } from 'framework7-react';
import { FC, useMemo, useState } from 'react';

import { event } from 'cypress/types/jquery';
import * as dayjs from 'dayjs';
import { motion } from 'framer-motion';

import { FeedEvent, useEventQuery, useUnauthorizedEventsQuery } from '@queries/EventQueries';
import { useSeriesByTagSlug } from '@queries/TagQueries';
import { useCurrentUser, useInterestQuery } from '@queries/UserQueries';

import { useEventFilters } from '@hooks/useEventFilters';

import { useLocation, useNavigate, useParams } from '@router/index';

import { createScreenComponent } from '@utilities/createScreenComponent';
import { mapStringToParagraphs } from '@utilities/mapStringToParagraphs';

import Button from '@components/buttons/Button';
import IconButton from '@components/buttons/IconButton';
import EventCard, {
  EventActions,
  EventHeader,
  EventImage,
  PromoterHeader,
} from '@components/cards/EventCard';
import { VenueCard } from '@components/cards/VenueCard';
import { ChipRadioOptions } from '@components/chips/ChipRadioOptions';
import { DatePicker } from '@components/form/DatePicker';
import { DateRangePicker } from '@components/form/DateRangePicker';
import { Input } from '@components/form/Input';
import { SearchField } from '@components/form/SearchField/SearchField';
import { FontAwesomeIcon } from '@components/icons/FontAwesomeIcon';
import { EmptyState } from '@components/layout/EmptyState/EmptyState';
import { HorizontalScroll } from '@components/layout/HorizontalScroll/HorizontalScroll';
import { LinkBanner } from '@components/layout/LinkBanner/LinkBanner';
import { Screen } from '@components/layout/Screen/Screen';
import { Skeleton } from '@components/layout/Skeleton/Skeleton';
import { TitleToolbar } from '@components/layout/TitleToolbar/TitleToolbar';

import { SeriesEventCard } from '../components/SeriesEventCard';

const CAN_ADD_EVENTS = false;

interface SeriesScreenProps {
  tagSlug: string;
}

export const SeriesEventsScreen = createScreenComponent<SeriesScreenProps>(
  ({ tagSlug, f7route }) => {
    const { series } = useSeriesByTagSlug(tagSlug);
    const navigate = useNavigate();

    const handleViewEvent = (eventId: string) => {
      navigate(`/events/${eventId}`);
    };

    const { startDate = dayjs().format('YYYY-MM-DD'), endDate, search = '' } = f7route.query;

    const { eventsAreReady, events = [] } = useUnauthorizedEventsQuery(
      {
        search,
        startDate,
        endDate,
        cityId: series?.city?._id,
        tagIds: [series?._id],
        rsvpData: 'ignore',
      },
      Boolean(series?.city?._id && series._id),
    );

    return (
      <Screen
        name="Series Screen"
        hideToolbar
        headerProps={{ back: { to: `/noauth/series/${tagSlug}` }, title: <Screen.GagglTitle /> }}
        // bottomToolbar={
        //   <Screen.BottomToolbar>
        //     <Button startIcon={<FontAwesomeIcon name="plus" />} roundness="rounded">
        //       Add Event
        //     </Button>
        //   </Screen.BottomToolbar>
        // }
      >
        <Screen.Content maxWidth={425}>
          <TitleToolbar text={series?.title} />
          <TitleToolbar
            text="Search Results"
            size="sm"
            action={
              eventsAreReady ? <span>{`${events.length} Total`}</span> : <Skeleton height={16} />
            }
          />
          {eventsAreReady ? (
            <>
              {events.length ? (
                <>
                  {events.map((event) => (
                    <SeriesEventCard key={event._id} event={event} onClick={handleViewEvent} />
                  ))}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="d-grid gap-2">
              <Skeleton height={360} />
              <Skeleton height={360} />
              <Skeleton height={360} />
            </div>
          )}
        </Screen.Content>
      </Screen>
    );
  },
);
