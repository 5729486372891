import { FC, ReactElement } from 'react';

import './HorizontalScroll.scss';

interface HorizontalScrollProps {
  items: ReactElement[];
}
export const HorizontalScroll: FC<HorizontalScrollProps> = ({ items }) => {
  return (
    <div className={`horizontal-scroll item-count-${items.length}`}>
      <div className="horizontal-scroll-inner">
        {items.map((item, i) => (
          <div key={i} className="horizontal-scroll-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
